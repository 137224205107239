import React, { useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import logo from './veloai-logo.png';

function Layout() {
  const location = useLocation();

  const navigation = [
    { name: 'Requirements Validator', href: '/requirements-validator' },
    { name: 'Test Scenario Design', href: '/test-scenario-design' },
    { name: 'Test Case Creation', href: '/test-case-creation' },
    { name: 'Automation Development', href: '/automation-development' },
    { name: 'Coverage Optimisation', href: '/coverage-optimisation' },
    { name: 'Unit Test Generator', href: '/unit-test-generator' },
    { name: 'Coverage Analyzer', href: '/unit-test-coverage-analyzer' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-neutral-950 to-black">
      <nav className="bg-neutral-900/50 backdrop-blur-sm border-b border-neutral-800/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img className="h-20 w-auto" src={logo} alt="VeloAI" />
              </Link>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`${
                      location.pathname === item.href
                        ? 'border-velo-orange-500 text-white'
                        : 'border-transparent text-gray-300 hover:border-gray-300 hover:text-white'
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium transition-colors duration-200`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
