import { extractPdfText } from './pdfProcessor';
import { extractImageText } from './imageProcessor';
import { extractDocxText, extractLegacyDocText } from './wordProcessor';
import { extractExcelText } from './excelProcessor';
import { extractTextContent } from './textProcessor';
import { getFileType } from '../fileTypeHelper';

export async function processFile({ file, fileName, fileType }) {
  const fileCategory = getFileType(fileType, fileName);

  switch (fileCategory) {
    case 'pdf':
      console.log('Processing PDF file:', fileName);
      const pdfText = await extractPdfText(file, fileName);
      console.log("pdfText", pdfText);
      return pdfText.content;
    case 'image':
      console.log('Processing image file:', fileName);
      const imageText = await extractImageText(file, fileName);
      return imageText.content;
    case 'docx' || 'doc':
      console.log('Processing docx file:', fileName);
      const docxText = await extractDocxText(file, fileName);
      return docxText.content;
    case 'excel':
      console.log('Processing excel file:', fileName);
      const excelText = await extractExcelText(file, fileName);
      return excelText.content;
    case 'text':
      console.log('Processing text file:', fileName);
      const text = await extractTextContent(file, fileName);
      return text.content;
    default:
      throw new Error(`Unsupported file type: ${fileType}`);
  }
}