import { read, utils } from 'xlsx';

export async function extractExcelText(file, fileName) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = read(arrayBuffer, { type: 'array' });
    let fullText = '';

    // Process each sheet in the workbook
    for (const sheetName of workbook.SheetNames) {
      const worksheet = workbook.Sheets[sheetName];
      
      // Convert sheet to JSON for better data handling
      const jsonData = utils.sheet_to_json(worksheet, { header: 1, raw: false });
      
      // Add sheet name as a header
      fullText += `Sheet: ${sheetName}\n`;
      
      // Process each row
      jsonData.forEach((row, rowIndex) => {
        if (row && row.length > 0) {
          // Filter out empty cells and join with appropriate separator
          const rowContent = row
            .map(cell => cell?.toString().trim() || '')
            .filter(cell => cell !== '')
            .join(' | ');
            
          if (rowContent) {
            fullText += `${rowContent}\n`;
          }
        }
      });
      
      fullText += '\n'; // Add spacing between sheets
    }

    return {
      name: fileName,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      content: fullText.trim(),
    };
  } catch (error) {
    console.error('Excel processing error:', error);
    throw new Error(`Failed to process Excel file ${fileName}: ${error.message}`);
  }
}
