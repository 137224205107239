import React, { useState } from 'react';
import { validateRequirements, rewriteRequirements } from '../services/aiService';
import { Link } from 'react-router-dom';
import logo from './veloai-logo.png';
import TextArea from './TextArea';
import { processFile } from '../utils/processors/fileProcessor';

interface ValidationResult {
  feedback: any;
}

interface NewRewrittenRequirements {
  feedback: any;
}

interface RequirementContext {
  diagrams?: File[];
  documents?: File[];
  additionalNotes: string;
}

interface FileContent {
  name: string;
  type: string;
}

function ValidationResultsDisplay({ results, newrequirements }: { results: ValidationResult, newrequirements: NewRewrittenRequirements }) {
  const [activeCategories, setActiveCategories] = useState<string[]>([]);
  let feedback: any;
  let feedbackrewritten: any;

  try {
    feedback = typeof results === 'string' ? JSON.parse(results) : results;
    feedbackrewritten = typeof newrequirements === 'string' ? JSON.parse(newrequirements) : newrequirements;
  } catch (error) {
    console.error('Error parsing feedback:', error);
    feedback = {};
  }

  const toggleCategory = (category: string) => {
    setActiveCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const CategoryHeader = ({ title, isActive, onClick }: { title: string, isActive: boolean, onClick: () => void }) => (
    <button
      onClick={onClick}
      className="w-full flex items-center justify-between p-4 text-left text-lg font-medium text-velo-orange-500/80 hover:bg-velo-blue-900/30 transition-colors duration-200"
    >
      <span>{title}</span>
      <span className="transform transition-transform duration-200" style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }}>
        ▼
      </span>
    </button>
  );

  return (
    <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
      <div className="px-8 py-6">
        <h2 className="text-2xl font-bold text-white mb-6">Validation Results</h2>
        
        {/* Clarity Analysis */}
        {feedback?.ClarityAnalysis?.AmbiguousTerms && (
          <div className="mb-2">
            <CategoryHeader 
              title="Clarity Analysis" 
              isActive={activeCategories.includes('clarity')}
              onClick={() => toggleCategory('clarity')}
            />
            {activeCategories.includes('clarity') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Clarity Analysis</h3>
                {feedback.ClarityAnalysis.AmbiguousTerms.map((item: any, index: number) => (
                  <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-velo-orange-500/80 font-medium">Requirement ID:</p>
                    <p className="text-gray-300">{item.RequirementID}</p>
                    <p className="text-velo-orange-500/80 font-medium mt-2">Ambiguous Term:</p>
                    <p className="text-gray-300">{item.Term}</p>
                    <p className="text-velo-orange-500/80 font-medium mt-2">Suggestion:</p>
                    <p className="text-gray-300">{item.Suggestion}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Testability Analysis */}
        {feedback?.TestabilityAnalysis && 
          (feedback.TestabilityAnalysis.TestableRequirements?.length > 0 || 
           feedback.TestabilityAnalysis.NonTestableRequirements?.length > 0) && (
          <div className="mb-2">
            <CategoryHeader 
              title="Testability Analysis" 
              isActive={activeCategories.includes('testability')}
              onClick={() => toggleCategory('testability')}
            />
            {activeCategories.includes('testability') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Testability Analysis</h3>
                
                {/* Testable Requirements */}
                {feedback.TestabilityAnalysis.TestableRequirements?.length > 0 && (
                  <div className="mb-4">
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Testable Requirements</h4>
                    {feedback.TestabilityAnalysis.TestableRequirements.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">ID:</span> {item.RequirementID}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Status:</span> {item.Status}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Comments:</span> {item.Comments}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/* Non-Testable Requirements */}
                {feedback.TestabilityAnalysis.NonTestableRequirements?.length > 0 && (
                  <div>
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Non-Testable Requirements</h4>
                    {feedback.TestabilityAnalysis.NonTestableRequirements.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">ID:</span> {item.RequirementID}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Issue:</span> {item.Issue}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Suggestion:</span> {item.Suggestion}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Buildability Assessment */}
        {feedback?.BuildabilityAssessment && 
          (feedback.BuildabilityAssessment.FeasibleRequirements?.length > 0 || 
           feedback.BuildabilityAssessment.ChallengingRequirements?.length > 0) && (
          <div className="mb-2">
            <CategoryHeader 
              title="Buildability Assessment" 
              isActive={activeCategories.includes('buildability')}
              onClick={() => toggleCategory('buildability')}
            />
            {activeCategories.includes('buildability') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Buildability Assessment</h3>
                
                {/* Feasible Requirements */}
                {feedback.BuildabilityAssessment.FeasibleRequirements?.length > 0 && (
                  <div className="mb-4">
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Feasible Requirements</h4>
                    {feedback.BuildabilityAssessment.FeasibleRequirements.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">ID:</span> {item.RequirementID}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Status:</span> {item.Status}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Comments:</span> {item.Comments}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/* Challenging Requirements */}
                {feedback.BuildabilityAssessment.ChallengingRequirements?.length > 0 && (
                  <div>
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Challenging Requirements</h4>
                    {feedback.BuildabilityAssessment.ChallengingRequirements.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">ID:</span> {item.RequirementID}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Issue:</span> {item.Issue}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Suggestion:</span> {item.Suggestion}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Consistency Verification */}
        {feedback?.ConsistencyVerification && 
          (feedback.ConsistencyVerification.Conflicts?.length > 0 || 
           feedback.ConsistencyVerification.Redundancies?.length > 0) && (
          <div className="mb-2">
            <CategoryHeader 
              title="Consistency Verification" 
              isActive={activeCategories.includes('consistency')}
              onClick={() => toggleCategory('consistency')}
            />
            {activeCategories.includes('consistency') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Consistency Verification</h3>
                
                {/* Conflicts */}
                {feedback.ConsistencyVerification.Conflicts?.length > 0 && (
                  <div className="mb-4">
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Conflicts</h4>
                    {feedback.ConsistencyVerification.Conflicts.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement 1:</span> {item.RequirementID1}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement 2:</span> {item.RequirementID2}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Conflict:</span> {item.ConflictDescription}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/* Redundancies */}
                {feedback.ConsistencyVerification.Redundancies?.length > 0 && (
                  <div>
                    <h4 className="text-velo-orange-500/80 font-medium mb-2">Redundancies</h4>
                    {feedback.ConsistencyVerification.Redundancies.map((item: any, index: number) => (
                      <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement 1:</span> {item.RequirementID1}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement 2:</span> {item.RequirementID2}</p>
                        <p className="text-gray-300"><span className="text-velo-orange-500/80">Description:</span> {item.RedundancyDescription}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Completeness Check */}
        {feedback?.CompletenessCheck?.MissingRequirements?.length > 0 && (
          <div className="mb-2">
            <CategoryHeader 
              title="Completeness Check" 
              isActive={activeCategories.includes('completeness')}
              onClick={() => toggleCategory('completeness')}
            />
            {activeCategories.includes('completeness') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Completeness Check</h3>
                {feedback.CompletenessCheck.MissingRequirements.map((item: any, index: number) => (
                  <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement ID:</span> {item.RequirementID}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Missing Aspect:</span> {item.MissingAspect}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Suggestion:</span> {item.SuggestedRequirement}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Prototyping and Review */}
        {feedback?.PrototypingAndReview?.UsabilityIssues?.length > 0 && (
          <div className="mb-2">
            <CategoryHeader 
              title="Prototyping and Review" 
              isActive={activeCategories.includes('prototyping')}
              onClick={() => toggleCategory('prototyping')}
            />
            {activeCategories.includes('prototyping') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Prototyping and Review</h3>
                {feedback.PrototypingAndReview.UsabilityIssues.map((item: any, index: number) => (
                  <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement ID:</span> {item.RequirementID}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Issue:</span> {item.IssueDescription}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Improvement:</span> {item.SuggestedImprovement}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Inspection Techniques */}
        {feedback?.InspectionTechniques?.IssuesFound?.length > 0 && (
          <div className="mb-2">
            <CategoryHeader 
              title="Inspection Findings" 
              isActive={activeCategories.includes('inspection')}
              onClick={() => toggleCategory('inspection')}
            />
            {activeCategories.includes('inspection') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Inspection Findings</h3>
                {feedback.InspectionTechniques.IssuesFound.map((item: any, index: number) => (
                  <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement ID:</span> {item.RequirementID}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Issue Type:</span> {item.IssueType}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Description:</span> {item.IssueDescription}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Correction:</span> {item.SuggestedCorrection}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Documentation of Findings */}
        {feedback?.DocumentationOfFindings?.Findings && (
          <div className="mb-2">
            <CategoryHeader 
              title="Documented Findings" 
              isActive={activeCategories.includes('findings')}
              onClick={() => toggleCategory('findings')}
            />
            {activeCategories.includes('findings') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Documented Findings</h3>
                {feedback.DocumentationOfFindings.Findings.map((item: any, index: number) => (
                  <div key={index} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Requirement ID:</span> {item.RequirementID}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Category:</span> {item.Category}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Issue:</span> {item.IssueDescription}</p>
                    <p className="text-gray-300"><span className="text-velo-orange-500/80">Solution:</span> {item.ProposedSolution}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Final Report */}
        {feedback?.FinalReport && (
          <div className="mb-2">
            <CategoryHeader 
              title="Final Report" 
              isActive={activeCategories.includes('final')}
              onClick={() => toggleCategory('final')}
            />
            {activeCategories.includes('final') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Final Report</h3>
                
                <div className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                  <p className="text-velo-orange-500/80 font-medium">Summary:</p>
                  <p className="text-gray-300 mb-4">{feedback.FinalReport.Summary}</p>
                  
                  <p className="text-velo-orange-500/80 font-medium">Recommendations:</p>
                  {feedback.FinalReport.Recommendations.map((rec: string, index: number) => (
                    <p key={index} className="text-gray-300">• {rec}</p>
                  ))}
                  
                  <p className="text-velo-orange-500/80 font-medium mt-4">Validated Requirements:</p>
                  {feedback.FinalReport.ValidatedRequirements.map((req: any, index: number) => (
                    <div key={index} className="border-t border-velo-blue-500/20 mt-2 pt-2">
                      <p className="text-gray-300"><span className="text-velo-orange-500/80">ID:</span> {req.RequirementID}</p>
                      <p className="text-gray-300"><span className="text-velo-orange-500/80">Status:</span> {req.Status}</p>
                      {req.Issues.length > 0 && (
                        <>
                          <p className="text-velo-orange-500/80 font-medium mt-1">Issues:</p>
                          {req.Issues.map((issue: string, idx: number) => (
                            <p key={idx} className="text-gray-300">• {issue}</p>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Rewritten Requirements */}
        {feedbackrewritten && (
          <div className="mb-2">
            <CategoryHeader 
              title="Rewritten Requirements" 
              isActive={activeCategories.includes('rewritten')}
              onClick={() => toggleCategory('rewritten')}
            />
            {activeCategories.includes('rewritten') && (
              <div className="p-4 backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Rewritten Requirements</h3>
                {feedbackrewritten.requirements.map((requirement: { id: string; description: string; acceptanceCriteria: Array<{ criterion: string }> }, index: number) => (
                  <div key={requirement.id} className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 mb-3">
                    <p className="text-gray-300">
                      <span className="text-velo-orange-500/80">ID:</span> {requirement.id}
                    </p>
                    <p className="text-gray-300">
                      <span className="text-velo-orange-500/80">Description:</span> {requirement.description}
                    </p>
                    {requirement.acceptanceCriteria.length > 0 && (
                      <>
                        <p className="text-velo-orange-500/80 font-medium mt-2">Acceptance Criteria:</p>
                        {requirement.acceptanceCriteria.map((criteria, criteriaIndex) => (
                          <p key={criteriaIndex} className="text-gray-300">• {criteria.criterion}</p>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

      </div>
    </div>
  );
}


  function RequirementsValidator() {
    const [requirements, setRequirements] = useState('');
    const [validationResults, setValidationResults] = useState<ValidationResult | null>(null);
    const [rewrittenRequirements, setRewrittenRequirements] = useState<NewRewrittenRequirements | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [context, setContext] = useState<RequirementContext>({
      diagrams: [],
      documents: [],
      additionalNotes: ''
    });

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, type: 'diagrams' | 'documents') => {
      const files = Array.from(event.target.files || []);
      setContext(prev => ({
        ...prev,
        [type]: [...(prev[type] || []), ...files]
      }));
    };

    const removeFile = (type: 'diagrams' | 'documents', index: number) => {
      setContext(prev => ({
        ...prev,
        [type]: prev[type]?.filter((_, i) => i !== index)
      }));
    };

    const handleValidation = async () => {
      setIsLoading(true);
      try {
        const allFiles = [...(context.diagrams || []), ...(context.documents || [])];
        let contextData = "";
        //loop trough all the files and process them by using processFile
        for (const file of allFiles) {
          const extractedText = await processFile({
            file: file,
            fileName: file.name,
            fileType: file.type
          });
          contextData += extractedText + "\n";
          console.log("extractedText", extractedText);
          console.log("contextData", contextData);
        }

        const results = await validateRequirements(requirements, contextData);
        const newrequirements = await rewriteRequirements(requirements, results);
        setValidationResults(results);
        setRewrittenRequirements(newrequirements);
        setError(null);
      } catch (error) {
        console.error('Error during validation:', error);
        setError('An error occurred during validation');
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Requirements Validator
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Context Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl mb-8">
            <div className="px-8 py-6">
              <h2 className="text-2xl font-bold text-white mb-6">Additional Context</h2>
              
              {/* Diagrams Upload */}
              <div className="mb-6">
                <label className="block text-velo-orange-500/80 text-sm font-medium mb-2">
                  Diagrams (UML, Flowcharts, etc.)
                </label>
                <div className="flex flex-col space-y-2">
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    multiple
                    onChange={(e) => handleFileUpload(e, 'diagrams')}
                    className="hidden"
                    id="diagram-upload"
                  />
                  <label
                    htmlFor="diagram-upload"
                    className="cursor-pointer inline-flex items-center px-4 py-2 border border-velo-blue-500/20 rounded-lg text-sm text-gray-300 hover:bg-velo-blue-900/30 transition-colors duration-200"
                  >
                    <span>Upload Diagrams</span>
                  </label>
                  {/* Display uploaded diagrams */}
                  {context.diagrams?.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-black/20 rounded-lg p-2">
                      <span className="text-gray-300 text-sm">{file.name}</span>
                      <button
                        onClick={() => removeFile('diagrams', index)}
                        className="text-red-400 hover:text-red-300"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Documents Upload */}
              <div className="mb-6">
                <label className="block text-velo-orange-500/80 text-sm font-medium mb-2">
                  Documents (Requirements, Specs, etc.)
                </label>
                <div className="flex flex-col space-y-2">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt,.json,.md"
                    multiple
                    onChange={(e) => handleFileUpload(e, 'documents')}
                    className="hidden"
                    id="document-upload"
                  />
                  <label
                    htmlFor="document-upload"
                    className="cursor-pointer inline-flex items-center px-4 py-2 border border-velo-blue-500/20 rounded-lg text-sm text-gray-300 hover:bg-velo-blue-900/30 transition-colors duration-200"
                  >
                    <span>Upload Documents</span>
                  </label>
                  {/* Display uploaded documents */}
                  {context.documents?.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-black/20 rounded-lg p-2">
                      <span className="text-gray-300 text-sm">{file.name}</span>
                      <button
                        onClick={() => removeFile('documents', index)}
                        className="text-red-400 hover:text-red-300"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Additional Notes */}
              <div className="mb-6">
                <label className="block text-velo-orange-500/80 text-sm font-medium mb-2">
                  Additional Notes
                </label>
                <textarea
                  value={context.additionalNotes}
                  onChange={(e) => setContext(prev => ({ ...prev, additionalNotes: e.target.value }))}
                  placeholder="Add any additional context, constraints, or background information..."
                  rows={4}
                  className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 text-gray-300 placeholder-gray-500 focus:outline-none focus:border-velo-blue-500/40"
                />
              </div>
            </div>
          </div>

          {/* Requirements Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Requirements"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here..."
                rows={10}
              />
            </div>
          </div>

          {/* Validate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleValidation}
              disabled={isLoading || !requirements.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Validating Requirements</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Validate Requirements</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Validation Results */}
          {!error && !isLoading && validationResults && (
            <ValidationResultsDisplay
              results={validationResults}
              newrequirements={rewrittenRequirements!}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RequirementsValidator;

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};
