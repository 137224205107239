import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { generateAutomationCode, scrapeDOMStructure } from '../services/aiService';
import logo from './veloai-logo.png';

interface AutomationCode {
  featureFile: string;
  stepDefinitions: string;
  pageObjects: string;
}

const defaultAutomationCode: AutomationCode = {
  featureFile: "No valid feature file could be generated.",
  stepDefinitions: "No valid step definitions could be generated.",
  pageObjects: "No valid page objects could be generated."
};

function AutomationDevelopment() {
  const [testCases, setTestCases] = useState('');
  const [automationCode, setAutomationCode] = useState<AutomationCode>(defaultAutomationCode);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isGenerated, setIsGenerated] = useState(false);
  const [url, setUrl] = useState('');

  const handleGenerateAutomationCode = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let domStructure;
      if (url.trim()) {
        domStructure = await scrapeDOMStructure(url);
      }
      const result = await generateAutomationCode(testCases, domStructure);
      if (isValidAutomationCode(result)) {
        setAutomationCode(result);
        setIsGenerated(true);
      } else {
        setAutomationCode(defaultAutomationCode);
        setError('Invalid automation code generated. Please try again with different test cases.');
      }
    } catch (error) {
      console.error('Error generating automation code:', error);
      setAutomationCode(defaultAutomationCode);
      setError('An error occurred while generating automation code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isValidAutomationCode = (code: any): code is AutomationCode => {
    return (
      typeof code === 'object' &&
      typeof code.featureFile === 'string' &&
      typeof code.stepDefinitions === 'string' &&
      typeof code.pageObjects === 'string'
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Automation Development
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Target URL Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <label className="block text-sm font-medium text-gray-200 mb-2">Target URL (Optional)</label>
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter the website URL to analyze..."
                className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white placeholder-gray-400"
              />
            </div>
          </div>

          {/* Test Cases Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <label className="block text-sm font-medium text-gray-200 mb-2">Test Cases</label>
              <textarea
                value={testCases}
                onChange={(e) => setTestCases(e.target.value)}
                placeholder="Enter your test cases here..."
                rows={10}
                className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white placeholder-gray-400"
              />
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center mt-8">
            <button
              onClick={handleGenerateAutomationCode}
              disabled={isLoading || !testCases.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Code</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Automation Code</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Output Sections */}
          {!error && !isLoading && isGenerated && (
            <div className="grid grid-cols-1 gap-8 mt-8">
              {/* Feature File */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <label className="block text-sm font-medium text-gray-200 mb-2">Feature File</label>
                  <textarea
                    value={automationCode.featureFile}
                    readOnly
                    rows={10}
                    className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white"
                  />
                </div>
              </div>

              {/* Step Definitions */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <label className="block text-sm font-medium text-gray-200 mb-2">Step Definitions</label>
                  <textarea
                    value={automationCode.stepDefinitions}
                    readOnly
                    rows={10}
                    className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white"
                  />
                </div>
              </div>

              {/* Page Objects */}
              <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
                <div className="px-8 py-6">
                  <label className="block text-sm font-medium text-gray-200 mb-2">Page Objects</label>
                  <textarea
                    value={automationCode.pageObjects}
                    readOnly
                    rows={10}
                    className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg p-3 text-white"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AutomationDevelopment;
