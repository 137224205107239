import React from 'react';
import { Link } from 'react-router-dom';
import logo from './veloai-logo.png';

const modules = [
  { id: '01', name: 'REQUIREMENTS VALIDATOR', path: '/requirements-validator', description: 'Automatically verifies and validates project requirements against best practices and standards to ensure clarity and completeness' },
  { id: '02', name: 'TEST SCENARIO DESIGN', path: '/test-scenario-design', description: 'Designs comprehensive test scenarios based on requirements' },
  { id: '03', name: 'TEST CASE CREATION', path: '/test-case-creation', description: 'Creates detailed test cases based on scenarios' },
  { id: '04', name: 'AUTOMATION DEVELOPMENT', path: '/automation-development', description: 'Develops automated test scripts for efficient testing' },
  { id: '05', name: 'COVERAGE OPTIMISATION', path: '/coverage-optimisation', description: 'Optimizes test coverage to ensure maximum efficiency' },
  { id: '06', name: 'UNIT TEST GENERATOR', path: '/unit-test-generator', description: 'Generates unit tests for code components' },
  { id: '07', name: 'UNIT TEST COVERAGE ANALYZER', path: '/unit-test-coverage-analyzer', description: 'Analyzes and reports on unit test coverage' },
];

function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-neutral-950 via-neutral-850 to-black">
      {/* Hero Section */}
      {/* Modules Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {modules.map((module) => (
            <Link
              key={module.id}
              to={module.path}
              className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-neutral-850/50 to-neutral-950/50 p-6 backdrop-blur-sm border border-velo-blue-700/30 hover:border-velo-orange-500/50 transition-all duration-300 hover:shadow-glow transform hover:-translate-y-1"
            >
              {/* Module Number Badge */}
              <div className="absolute top-4 right-4 bg-velo-orange-500/20 text-velo-orange-500 text-sm font-bold py-1 px-2 rounded-md border border-velo-orange-500/30">
                {module.id}
              </div>

              {/* Content */}
              <div className="mt-4">
                <h2 className="text-xl font-bold text-white mb-3 group-hover:text-velo-orange-500 transition-colors">
                  {module.name}
                </h2>
                <p className="text-gray-400 group-hover:text-gray-300 transition-colors text-sm">
                  {module.description}
                </p>
              </div>

              {/* Hover Effect Overlay */}
              <div className="absolute inset-0 bg-gradient-to-r from-velo-orange-500/0 to-velo-orange-500/0 group-hover:from-velo-orange-500/5 group-hover:to-transparent transition-all duration-300" />
            </Link>
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="py-8 text-center text-gray-400 border-t border-velo-blue-800/30">
        <p className="text-sm">
          Powered by VeloAI © {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
}

export default LandingPage;
