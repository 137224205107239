import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { generateTestScenarios } from '../services/aiService';
import logo from './veloai-logo.png';
import TextArea from '../components/TextArea';

interface TestScenario {
  "Scenario ID": string;
  "Test Scenario": string;
  "Test Level": string;
  "Test Type": string;
  "Test Design Technique": string;
  "Priority": string;
  "Risk": string;
  "Execution Order": string;
}

const defaultScenario: TestScenario = {
  "Scenario ID": "N/A",
  "Test Scenario": "N/A",
  "Test Level": "N/A",
  "Test Type": "N/A",
  "Test Design Technique": "N/A",
  "Priority": "N/A",
  "Risk": "N/A",
  "Execution Order": "N/A"
};

function TestScenarioDesign() {
  const [requirements, setRequirements] = useState('');
  const [scenarios, setScenarios] = useState<TestScenario[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'Priority' | 'Risk' | 'Execution Order' | null>(null);

  const sortedScenarios = React.useMemo(() => {
    if (!sortBy) return scenarios;
    
    return [...scenarios].sort((a, b) => {
      const priorityOrder = { 'High': 3, 'Medium': 2, 'Low': 1 };
      const getValue = (value: string) => priorityOrder[value as keyof typeof priorityOrder] || 0;
      
      return getValue(b[sortBy]) - getValue(a[sortBy]);
    });
  }, [scenarios, sortBy]);

  const handleGenerateScenarios = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await generateTestScenarios(requirements);
      const parsedResult = typeof result === 'string' ? JSON.parse(result) : result;
      setScenarios(parsedResult.scenarios);
    } catch (error) {
      console.error('Error generating scenarios:', error);
      setScenarios([defaultScenario]);
      setError('An error occurred while generating scenarios. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Test Scenario Design
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Requirements Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Requirements"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here..."
                rows={10}
              />
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleGenerateScenarios}
              disabled={isLoading || !requirements.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Scenarios</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Test Scenarios</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Generated Scenarios */}
          {scenarios.length > 0 && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-white">Generated Test Scenarios</h2>
                  <select
                    value={sortBy || ''}
                    onChange={(e) => setSortBy(e.target.value as 'Priority' | 'Risk' | 'Execution Order' | null)}
                    className="bg-velo-blue-900/30 text-white border border-velo-blue-500/30 rounded-lg px-4 py-2"
                  >
                    <option value="">No Sorting</option>
                    <option value="Priority">Sort by Priority</option>
                    <option value="Risk">Sort by Risk</option>
                    <option value="Execution Order">Sort by Execution Order</option>
                  </select>
                </div>
                <div className="space-y-6">
                  {sortedScenarios.map((scenario) => (
                    <div 
                      key={scenario["Scenario ID"]} 
                      className="backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30 p-6"
                    >
                      <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">
                        Scenario {scenario["Scenario ID"]}
                      </h3>
                      <div className="space-y-3 text-gray-300">
                        <p><span className="text-gray-400">Scenario ID:</span> {scenario["Scenario ID"]}</p>
                        <p><span className="text-gray-400">Test Scenario:</span> {scenario["Test Scenario"]}</p>
                        <p><span className="text-gray-400">Test Level:</span> {scenario["Test Level"]}</p>
                        <p><span className="text-gray-400">Test Type:</span> {scenario["Test Type"]}</p>
                        <p><span className="text-gray-400">Test Design Technique:</span> {scenario["Test Design Technique"]}</p>
                        <p><span className="text-gray-400">Priority:</span> {scenario["Priority"]}</p>
                        <p><span className="text-gray-400">Risk:</span> {scenario["Risk"]}</p>
                        <p><span className="text-gray-400">Execution Order:</span> {scenario["Execution Order"]}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestScenarioDesign;
