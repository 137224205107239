export async function extractTextContent(file, fileName) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = (event) => {
            const text = event.target.result;
            resolve({
                name: fileName,
                type: 'text/plain',
                content: text.trim(),
            });
        };
        
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
    });
}