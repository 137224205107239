import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './components/LandingPage';
import RequirementsValidator from './components/requirementsValidator';
import TestScenarioDesign from './components/TestScenarioDesign';
import TestCaseCreation from './components/TestCaseCreation';
import AutomationDevelopment from './components/AutomationDevelopment';
import CoverageOptimisation from './components/CoverageOptimisation';
import UnitTestGenerator from './components/UnitTestGenerator';
import UnitTestCoverageAnalyzer from './components/UnitTestCoverageAnalyzer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/requirements-validator" element={<RequirementsValidator />} />
          <Route path="/test-scenario-design" element={<TestScenarioDesign />} />
          <Route path="/test-case-creation" element={<TestCaseCreation />} />
          <Route path="/automation-development" element={<AutomationDevelopment />} />
          <Route path="/coverage-optimisation" element={<CoverageOptimisation />} />
          <Route path="/unit-test-generator" element={<UnitTestGenerator />} />
          <Route path="/unit-test-coverage-analyzer" element={<UnitTestCoverageAnalyzer />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;