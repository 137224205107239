import { renderAsync } from 'docx-preview';

export async function extractDocxText(file, fileName) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    
    // Create a temporary div to render the content
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    document.body.appendChild(container);
    
    // Render the document
    await renderAsync(arrayBuffer, container);
    
    // Extract text content
    const content = container.innerText;
    
    // Clean up
    document.body.removeChild(container);
    
    return {
      name: fileName,
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      content: content.trim(),
    };
  } catch (error) {
    console.error('DOCX processing error:', error);
    throw error;
  }
}