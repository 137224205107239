import { createWorker } from 'tesseract.js';

export async function extractImageText(file, fileName) {
  try {
    // Create worker with proper initialization
    const worker = await createWorker({
      logger: progress => {
        console.log('OCR Progress:', progress);
      }
    });

    // Convert file to data URL for Tesseract
    const dataUrl = await fileToDataUrl(file);

    // Initialize worker with English language
    await worker.loadLanguage('eng');
    await worker.initialize('eng');

    // Perform OCR
    const { data: { text } } = await worker.recognize(dataUrl);

    // Clean up worker
    await worker.terminate();

    return {
      name: fileName,
      type: 'image',
      content: text.trim(),
    };
  } catch (error) {
    console.error('Image processing error:', error);
    throw new Error(`Failed to process image file ${fileName}: ${error.message}`);
  }
}

// Helper function to convert File to Data URL
async function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}