interface TextAreaProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
}

const TextArea = ({ label, value, onChange, placeholder, rows = 3 }: TextAreaProps) => {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-200">
        {label}
      </label>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="w-full bg-black/20 border border-velo-blue-500/20 rounded-lg 
                   text-white placeholder-gray-500 px-4 py-3
                   focus:ring-2 focus:ring-velo-orange-500/50 focus:border-transparent
                   focus:outline-none transition duration-200"
      />
    </div>
  );
};

export default TextArea; 