import axios from 'axios';
import { REQUIREMENTS_VALIDATION_PROMPT, TEST_CASES_GENERATION_PROMPT } from '../constants/systemPrompts';
import { CREATE_SCENARIO_PROMPT } from '../constants/systemPrompts';


interface TestScenario {
  id: number;
  description: string;
  bddscenario: string;
  type: string;
  technique: string;
}

interface RequirementContext {
  additionalNotes?: string;
  diagrams?: string[];
  documents?: string[];
}

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = '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';
const gptmodel = 'gpt-4o-2024-08-06';

async function sendGPTRequest(payload: any) {
  const response = await axios.post(OPENAI_API_URL, payload, {
    headers: {
      'Authorization': `Bearer ${OPENAI_API_KEY}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.data.choices || response.data.choices.length === 0) {
    throw new Error('No choices returned from the OpenAI API');
  }

  return response.data.choices[0].message.content;
}

export async function validateRequirements(requirements: string, context?: any) {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: REQUIREMENTS_VALIDATION_PROMPT
        },
        {
          role: 'user',
          content: `Use the following relevant context, the additional notes, diagrams and documents that relate to the requirements when validating the following requirements:\n${context}\nRequirements to analyze:\n${requirements}`
        }
      ],
      temperature: 0.2,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'ValidationResultsSchema',
          strict: true,
          schema: {
            type: 'object',
            name: 'ValidationResults',
            properties: {
              "ClarityAnalysis": {
                "type": "object",
                "properties": {
                  "AmbiguousTerms": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the requirement (e.g., REQ-001)."
                        },
                        "Term": {
                          "type": "string",
                          "description": "The ambiguous term identified in the requirement."
                        },
                        "Suggestion": {
                          "type": "string",
                          "description": "Suggested clarification for the ambiguous term."
                        }
                      },
                      "required": ["RequirementID", "Term", "Suggestion"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["AmbiguousTerms"],
                "additionalProperties": false
              },
              "TestabilityAnalysis": {
                "type": "object",
                "properties": {
                  "TestableRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the testable requirement."
                        },
                        "Status": {
                          "type": "string",
                          "enum": ["Testable"],
                          "description": "Status indicating the requirement is testable."
                        },
                        "Comments": {
                          "type": "string",
                          "description": "Additional comments regarding the testability of the requirement."
                        }
                      },
                      "required": ["RequirementID", "Status", "Comments"],
                      "additionalProperties": false
                    }
                  },
                  "NonTestableRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the non-testable requirement."
                        },
                        "Issue": {
                          "type": "string",
                          "description": "Description of why the requirement is not testable."
                        },
                        "Suggestion": {
                          "type": "string",
                          "description": "Recommendation to enhance the testability of the requirement."
                        }
                      },
                      "required": ["RequirementID", "Issue", "Suggestion"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["TestableRequirements", "NonTestableRequirements"],
                "additionalProperties": false
              },
              "BuildabilityAssessment": {
                "type": "object",
                "properties": {
                  "FeasibleRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the feasible requirement."
                        },
                        "Status": {
                          "type": "string",
                          "enum": ["Feasible"],
                          "description": "Status indicating the requirement is feasible to implement."
                        },
                        "Comments": {
                          "type": "string",
                          "description": "Additional comments regarding the buildability of the requirement."
                        }
                      },
                      "required": ["RequirementID", "Status", "Comments"],
                      "additionalProperties": false
                    }
                  },
                  "ChallengingRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the challenging requirement."
                        },
                        "Issue": {
                          "type": "string",
                          "description": "Description of the buildability challenges associated with the requirement."
                        },
                        "Suggestion": {
                          "type": "string",
                          "description": "Recommendation to enhance the buildability of the requirement."
                        }
                      },
                      "required": ["RequirementID", "Issue", "Suggestion"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["FeasibleRequirements", "ChallengingRequirements"],
                "additionalProperties": false
              },
              "ConsistencyVerification": {
                "type": "object",
                "properties": {
                  "Conflicts": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID1": {
                          "type": "string",
                          "description": "Unique identifier for the first conflicting requirement."
                        },
                        "RequirementID2": {
                          "type": "string",
                          "description": "Unique identifier for the second conflicting requirement."
                        },
                        "ConflictDescription": {
                          "type": "string",
                          "description": "Detailed description of the conflict between the two requirements."
                        }
                      },
                      "required": ["RequirementID1", "RequirementID2", "ConflictDescription"],
                      "additionalProperties": false
                    }
                  },
                  "Redundancies": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID1": {
                          "type": "string",
                          "description": "Unique identifier for the first redundant requirement."
                        },
                        "RequirementID2": {
                          "type": "string",
                          "description": "Unique identifier for the second redundant requirement."
                        },
                        "RedundancyDescription": {
                          "type": "string",
                          "description": "Detailed description of the redundancy between the two requirements."
                        }
                      },
                      "required": ["RequirementID1", "RequirementID2", "RedundancyDescription"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["Conflicts", "Redundancies"],
                "additionalProperties": false
              },
              "CompletenessCheck": {
                "type": "object",
                "properties": {
                  "MissingRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the requirement with missing aspects."
                        },
                        "MissingAspect": {
                          "type": "string",
                          "description": "Category or aspect that is missing (e.g., Usability, Performance)."
                        },
                        "SuggestedRequirement": {
                          "type": "string",
                          "description": "Recommended addition to address the missing aspect."
                        }
                      },
                      "required": ["RequirementID", "MissingAspect", "SuggestedRequirement"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["MissingRequirements"],
                "additionalProperties": false
              },
              "InspectionTechniques": {
                "type": "object",
                "properties": {
                  "IssuesFound": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the requirement with identified issues."
                        },
                        "IssueType": {
                          "type": "string",
                          "description": "Type of issue found (e.g., Scope, Terminology)."
                        },
                        "IssueDescription": {
                          "type": "string",
                          "description": "Detailed description of the issue."
                        },
                        "SuggestedCorrection": {
                          "type": "string",
                          "description": "Recommended action to correct the issue."
                        }
                      },
                      "required": ["RequirementID", "IssueType", "IssueDescription", "SuggestedCorrection"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["IssuesFound"],
                "additionalProperties": false
              },
              "PrototypingAndReview": {
                "type": "object",
                "properties": {
                  "UsabilityIssues": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the requirement with usability issues."
                        },
                        "IssueDescription": {
                          "type": "string",
                          "description": "Description of the usability issue identified during prototyping."
                        },
                        "SuggestedImprovement": {
                          "type": "string",
                          "description": "Recommended improvement to address the usability issue."
                        }
                      },
                      "required": ["RequirementID", "IssueDescription", "SuggestedImprovement"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["UsabilityIssues"],
                "additionalProperties": false
              },
              "DocumentationOfFindings": {
                "type": "object",
                "properties": {
                  "Findings": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the requirement with documented findings."
                        },
                        "Category": {
                          "type": "string",
                          "description": "Category of the finding (e.g., Clarity, Buildability)."
                        },
                        "IssueDescription": {
                          "type": "string",
                          "description": "Detailed description of the issue found."
                        },
                        "ProposedSolution": {
                          "type": "string",
                          "description": "Recommended solution to address the issue."
                        }
                      },
                      "required": ["RequirementID", "Category", "IssueDescription", "ProposedSolution"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["Findings"],
                "additionalProperties": false
              },
              "FinalReport": {
                "type": "object",
                "properties": {
                  "Summary": {
                    "type": "string",
                    "description": "Overall summary of the validation process and key findings."
                  },
                  "Recommendations": {
                    "type": "array",
                    "items": {
                      "type": "string",
                      "description": "Recommendations for improving requirements based on validation findings."
                    }
                  },
                  "ValidatedRequirements": {
                    "type": "array",
                    "items": {
                      "type": "object",
                      "properties": {
                        "RequirementID": {
                          "type": "string",
                          "description": "Unique identifier for the validated requirement."
                        },
                        "Status": {
                          "type": "string",
                          "enum": ["Validated", "Validated with Recommendations", "Incomplete"],
                          "description": "Validation status of the requirement."
                        },
                        "Issues": {
                          "type": "array",
                          "items": {
                            "type": "string",
                            "description": "List of issues identified with the requirement."
                          }
                        }
                      },
                      "required": ["RequirementID", "Status", "Issues"],
                      "additionalProperties": false
                    }
                  }
                },
                "required": ["Summary", "Recommendations", "ValidatedRequirements"],
                "additionalProperties": false
              }
            },
            "required": [
              "ClarityAnalysis",
              "TestabilityAnalysis",
              "BuildabilityAssessment",
              "ConsistencyVerification",
              "CompletenessCheck",
              "InspectionTechniques",
              "PrototypingAndReview",
              "DocumentationOfFindings",
              "FinalReport"
            ],
            "additionalProperties": false
          }

        }
      }
    };


    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error validating requirements:', error);
    throw error;
  }
}

export async function rewriteRequirements(requirements: string, analysisResult: any) {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: "You are a requirements improvement expert. Use the given analysis to rewrite the requirements to make it fully complete, buildable, and testable without any ambiguities or missing elements."
        },
        {
          role: 'user',
          content: `Rewrite the requirement to incorporate all the analysis done so that the requirements are now fully buildable and testable. The requirements must be so comprehensive that, upon requirement analysis, no further improvements are found.\n\nAnalysis: ${JSON.stringify(analysisResult)}\n\nOriginal Requirement: ${requirements}. Please return only the rewritten requirements in JSON format.`
        }
      ],
      temperature: 0.2,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'RewrittenRequirementsSchema',
          strict: true,
          schema: {
            type: 'object',
            name: 'RewrittenRequirements',
            properties: {
              requirements: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    id: { type: 'string' },
                    description: { type: 'string' },
                    acceptanceCriteria: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          criterion: { type: 'string' }
                        },
                        required: ['criterion'],
                        additionalProperties: false
                      }
                    }
                  },
                  required: ['id', 'description', 'acceptanceCriteria'],
                  additionalProperties: false
                }
              }
            },
            required: ['requirements'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    console.log("content", content);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error rewriting requirements:', error);
  }
}


export async function generateTestScenarios(requirements: string): Promise<TestScenario[]> {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: CREATE_SCENARIO_PROMPT
        },
        {
          role: 'user',
          content: `Now, generate similar prioritized BDD test scenarios for the following requirement based on ISTQB and TMAP methodologies :\n\n${requirements}`
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'TestScenariosSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              scenarios: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  "Scenario ID": { type: 'string' },
                  "Test Scenario": { type: 'string' },
                  "Test Level": { type: 'string' },
                  "Test Type": { type: 'string' },
                  "Test Design Technique": { type: 'string' },
                  "Priority": { type: 'string' },
                  "Risk": { type: 'string' },
                  "Execution Order": { type: 'number' } 
                },
                required: ['Scenario ID', 'Test Scenario', 'Test Level', 'Test Type', 'Test Design Technique', 'Priority', 'Risk', 'Execution Order'],
                additionalProperties: false
              }
            }
          },
          required: ['scenarios'],
          additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error generating test scenarios:', error);
    return [];
  }
}

export async function generateTestCases(scenarios: string): Promise<any[]> {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: TEST_CASES_GENERATION_PROMPT
        },
        {
          role: 'user',
          content: `Generate BDD test cases for the following scenarios based on ISTQB and TMAP methodologies:\n\n${scenarios}`
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'TestCasesSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              "TestCases": {
                "type": "array",
                "items": {
                  "type": "object",
                  "properties": {
                    "TestCaseID": {
                      "type": "string",
                      "description": "A unique identifier for the test case (e.g., TC-001)."
                    },
                    "TestScenarioID": {
                      "type": "string",
                      "description": "The identifier of the related test scenario (e.g., TS-001)."
                    },
                    "TestCaseDescription": {
                      "type": "string",
                      "description": "A clear and concise description of what the test case is verifying."
                    },
                    "Preconditions": {
                      "type": "string",
                      "description": "Any prerequisites or setup required before executing the test case."
                    },
                    "TestSteps": {
                      "type": "array",
                      "items": {
                        "type": "string",
                        "description": "A step-by-step list of actions to perform the test."
                      },
                      "description": "A list of steps to execute the test case."
                    },
                    "TestData": {
                      "type": ["string", "object", "array"],
                      "description": "Specific data inputs needed for the test steps. Use 'N/A' if not applicable.",
                      "items": {
                        "type": ["string", "object"]
                      }
                    },
                    "ExpectedResult": {
                      "type": "string",
                      "description": "The expected outcome after executing the test steps."
                    },
                    "ActualResult": {
                      "type": "string",
                      "description": "To be filled during test execution."
                    },
                    "Status": {
                      "type": "string",
                      "enum": ["Pass", "Fail", "Blocked", "Not Executed"],
                      "description": "To be updated after test execution."
                    },
                    "Comments": {
                      "type": "string",
                      "description": "Any additional notes or observations."
                    }
                  },
                  "required": [
                    "TestCaseID",
                    "TestScenarioID",
                    "TestCaseDescription",
                    "Preconditions",
                    "TestSteps",
                    "TestData",
                    "ExpectedResult",
                    "ActualResult",
                    "Status",
                    "Comments"
                  ],
                  "additionalProperties": false
                },
                "description": "An array of detailed test cases generated from test scenarios."
              }
            },
            "required": ["TestCases"],
            "additionalProperties": false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error generating test cases:', error);
    return [];
  }
}

export async function generateAutomationCode(testCases: string, domStructure?: string): Promise<any> {
  try {
    const prompt = domStructure 
      ? `Generate end-to-end Cucumber automation code based on the given test cases and DOM structure. Use the actual page elements and their locators from the DOM structure. The code should follow the Page Object Model (POM) structure and be suitable for a Java Cucumber framework. Include proper page transitions and verifications for each step.\n\nDOM Structure:\n${domStructure}\n\nTest Cases:\n${testCases}`
      : `Generate end-to-end Cucumber automation code based on the given test cases. The code should follow the Page Object Model (POM) structure and be suitable for a Java Cucumber framework.\n\nTest Cases:\n${testCases}`;

    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: 'You are an expert automation developer. Generate precise automation code using actual page elements when available.'
        },
        {
          role: 'user',
          content: prompt
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'AutomationCodeSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              featureFile: { type: 'string' },
              stepDefinitions: { type: 'string' },
              pageObjects: { type: 'string' }
            },
            required: ['featureFile', 'stepDefinitions', 'pageObjects'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error generating automation code:', error);
    return null;
  }
}

export async function analyzeCoverage(requirements: string, existingScenarios: string): Promise<any> {
  try {
    const payload = {
      model: gptmodel,
      messages: [
        {
          role: 'system',
          content: `You are an expert test coverage analyst. Analyze the given requirements and existing test scenarios to determine the current test coverage by measuring the existing scenarios. Then, suggest additional test scenarios to improve coverage. Return the results in JSON format.`
        },
        {
          role: 'user',
          content: `Analyze coverage for the following:\n\nRequirements:\n${requirements}\n\nExisting Test Scenarios:\n${existingScenarios}`
        }
      ],
      temperature: 0.3,
      max_tokens: 16000,
      response_format: {
        type: 'json_schema',
        json_schema: {
          name: 'CoverageAnalysisSchema',
          strict: true,
          schema: {
            type: 'object',
            properties: {
              currentCoverage: { type: 'number' },
              suggestedScenarios: { type: 'array', items: { type: 'string' } }
            },
            required: ['currentCoverage', 'suggestedScenarios'],
            additionalProperties: false
          }
        }
      }
    };

    const content = await sendGPTRequest(payload);
    return JSON.parse(content);
  } catch (error) {
    console.error('Error analyzing coverage:', error);
    return null;
  }
}

export interface UnitTestResult {
  tests: string;
}

export async function generateUnitTests(sourceCode: string, requirements: string, testScenarios: string, language: string, framework: string): Promise<UnitTestResult> {
  try {
    // First, generate exhaustive unit tests
    const exhaustiveResponse = await axios.post(
      OPENAI_API_URL,
      {
        model: gptmodel, // or 'gpt-4-0613' if you have access
        messages: [
          {
            role: 'system',
            content: `You are an expert unit test developer proficient in multiple programming languages and testing frameworks. Generate comprehensive and exhaustive unit tests for the given source code, based on the provided requirements. Use the specified programming language and test framework. Focus on testing edge cases, boundary conditions, and both positive and negative scenarios. Ensure the tests are well-structured and follow best practices for the chosen framework and language.`
          },
          {
            role: 'user',
            content: `Generate exhaustive unit tests for the following:

            Language: ${language}
            Test Framework: ${framework}
            
            Source Code:
            ${sourceCode}
            
            Requirements:
            ${requirements}
            
            Please provide only the unit test code.`
          }
        ],
        temperature: 0.3,
        max_tokens: 16000
      },
      {
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );

    let generatedTests = exhaustiveResponse.data.choices[0].message.content.trim();
    let generatedTestsnew = "";

    // If user-provided test scenarios exist, ensure they are included
    if (testScenarios.trim()) {
      const scenarioResponse = await axios.post(
        OPENAI_API_URL,
        {
          model: gptmodel,
          messages: [
            {
              role: 'system',
              content: `You are an expert unit test developer. Your task is to analyze the given unit tests and ensure that specific test scenarios are covered. If any scenarios are not covered, add the necessary unit tests to cover them.`
            },
            {
              role: 'user',
              content: `Analyze the following unit tests and ensure that the provided test scenarios are covered. If any scenarios are not covered, add the necessary unit tests.

              Language: ${language}
              Test Framework: ${framework}
              
              Existing Unit Tests:
              ${generatedTests}
              
              Test Scenarios to Cover:
              ${testScenarios}
              
              Please provide the complete set of unit tests, including any additions to cover the specified scenarios.`
            }
          ],
          temperature: 0.3,
          max_tokens: 16000
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      generatedTestsnew = scenarioResponse.data.choices[0].message.content.trim();
    }

    return {
      tests: generatedTests + generatedTestsnew
    };
  } catch (error) {
    console.error('Error generating unit tests:', error);
    throw new Error('Failed to generate unit tests. Please try again.');
  }
}

export interface CoverageAnalysisResult {
  coverage: number;
  missingScenarios: string[];
}

export async function analyzeUnitCoverage(sourceCode: string, requirements: string, unitTests: string, language: string): Promise<CoverageAnalysisResult> {
  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: gptmodel, // or 'gpt-4-0613' if you have access
        messages: [
          {
            role: 'system',
            content: `You are an expert in unit testing and code coverage analysis. Analyze the given source code, requirements, and existing unit tests to determine the full exhaustive test coverage against the requirements and identify missing scenarios. Provide an estimated coverage percentage and a list of scenarios that should be added to ensure full exhaustive coverage.`
          },
          {
            role: 'user',
            content: `Analyze the unit test coverage for the following:

            Language: ${language}
            
            Source Code:
            ${sourceCode}
            
            Requirements:
            ${requirements}
            
            Existing Unit Tests:
            ${unitTests}
            
            Please provide an estimated exhaustive coverage percentage and a list of missing scenarios. Format your response as follows:
            
            Coverage: [Estimated coverage percentage]
            
            Missing Scenarios:
            - [Scenario 1]
            - [Scenario 2]
            - ...`
          }
        ],
        temperature: 0.3,
        max_tokens: 16000
      },
      {
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const analysisContent = response.data.choices[0].message.content.trim();
    const [coveragePart, scenariosPart] = analysisContent.split('Missing Scenarios:');
    
    const coverage = parseInt(coveragePart.split(':')[1].trim(), 10);
    const missingScenarios = scenariosPart.trim().split('\n').map((scenario: string) => scenario.replace(/^-\s*/, '').trim());

    return {
      coverage: isNaN(coverage) ? 0 : coverage,
      missingScenarios
    };
  } catch (error) {
    console.error('Error analyzing coverage:', error);
    throw new Error('Failed to analyze coverage. Please try again.');
  }
}

export async function scrapeDOMStructure(url: string): Promise<string> {
  try {
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
    const response = await axios.post(`${backendUrl}/api/scrape`, { url });
    return response.data.domStructure;
  } catch (error) {
    console.error('Error scraping DOM:', error);
    throw new Error('Failed to scrape webpage. Please check the URL and try again.');
  }
}

