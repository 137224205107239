import { getDocument } from 'pdfjs-dist/webpack';

export async function extractPdfText(file, fileName) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({
      data: arrayBuffer,
      useSystemFonts: true,
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${getDocument.version}/standard_fonts/`,
    }).promise;
    
    let fullText = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      fullText += textContent.items.map(item => item.str).join(' ') + '\n';
    }

    return {
      name: fileName,
      type: 'application/pdf',
      content: fullText.trim(),
    };
  } catch (error) {
    console.error('PDF processing error:', error);
    throw error;
  }
}
