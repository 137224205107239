import React, { useState } from 'react';
import { analyzeUnitCoverage, CoverageAnalysisResult } from '../services/aiService';
import { Link } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import logo from './veloai-logo.png';
import TextArea from '../components/TextArea';

function UnitTestCoverageAnalyzer() {
  const [sourceCode, setSourceCode] = useState('');
  const [requirements, setRequirements] = useState('');
  const [unitTests, setUnitTests] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [analysisResult, setAnalysisResult] = useState<CoverageAnalysisResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAnalyzeCoverage = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await analyzeUnitCoverage(sourceCode, requirements, unitTests, language);
      setAnalysisResult(result);
    } catch (error) {
      console.error('Error analyzing coverage:', error);
      setError('An error occurred while analyzing coverage. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-14 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Unit Test Coverage Analyzer
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Input Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Source Code */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Source Code"
                  value={sourceCode}
                  onChange={(e) => setSourceCode(e.target.value)}
                  placeholder="Enter your source code here..."
                  rows={10}
                />
              </div>
            </div>

            {/* Unit Tests */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Unit Tests"
                  value={unitTests}
                  onChange={(e) => setUnitTests(e.target.value)}
                  placeholder="Enter your unit tests here..."
                  rows={10}
                />
              </div>
            </div>
          </div>

          {/* Requirements */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Requirements"
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here..."
                rows={5}
              />
            </div>
          </div>

          {/* Analyze Button */}
          <div className="flex justify-center">
            <button
              onClick={handleAnalyzeCoverage}
              disabled={isLoading || !sourceCode.trim() || !requirements.trim() || !unitTests.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading 
                  ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Analyzing Coverage</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Analyze Coverage</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Analysis Results */}
          {analysisResult && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <h2 className="text-2xl font-bold text-white mb-6">Coverage Analysis</h2>
                
                {/* Coverage Percentage */}
                <div className="mb-8">
                  <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Coverage Score</h3>
                  <div className="bg-velo-blue-900/30 rounded-lg p-4">
                    <div className="relative h-4 bg-velo-blue-900/30 rounded-full overflow-hidden">
                      <div
                        className="absolute inset-y-0 left-0 bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 transition-all duration-500"
                        style={{ width: `${analysisResult.coverage}%` }}
                      />
                    </div>
                    <p className="text-right mt-2 text-velo-orange-500 font-medium">
                      {analysisResult.coverage}%
                    </p>
                  </div>
                </div>

                {/* Missing Scenarios */}
                <div>
                  <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">Missing Test Scenarios</h3>
                  <ul className="space-y-3">
                    {analysisResult.missingScenarios.map((scenario, index) => (
                      <li 
                        key={index}
                        className="text-gray-300 pl-4 border-l-2 border-velo-blue-500/30"
                      >
                        {scenario}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnitTestCoverageAnalyzer;
